export default {
  header: {
    name: "ALLEY",
    swap: "SWAP",
    home: "HOME",
    ido: "IDO",
    liquidity: "LIQUIDITY"
  },
  swap:{
    title: "Swap",
    from:"From",
    to:"To (estimated)",
    balance: "Balance",
    max: "Max",
    enter: "Enter a amount",
    insufficient: "Insufficient balance",
    connect: "Connect Wallet",
    slippage: "Slippage Tolerance",
    price: "Price",
    min: "Minimum received"
  },
  liquidity: {
    title1:"+ liquidity",
    title2:"- liquidity",
    amount:"Amount",
    balance: "Balance",
    fixed: "Fixed Ratio",
    get: "Minimum received",
    insufficient: "Insufficient balance",
    enter: "Anter Amount"
  },
  home:{
    slogan1: "The No.1 LEGO bricks",
    slogan2: "for DeFi projects for public chains."
  }
}
