export default {
  header: {
    name: "ALLEY",
    swap: "SWAP",
    home: "HOME",
    ido: "IDO",
    liquidity: "LIQUIDITY"
  },
  home:{
    slogan1: "The No.1 LEGO bricks",
    slogan2: "for DeFi projects for public chains."
  }
}
