import { client } from '@ont-dev/ontology-dapi'
import { BigNumber } from 'bignumber.js';
import { utils } from 'ontology-ts-sdk'
import state from '../config/mainnet-config.json'

client.registerClient({});
let account = sessionStorage.getItem("alley_address")

export async function getAddress() {
  let address = await client.api.asset.getAccount()
  console.log(address)
  console.log(state)
  return address
}

export async function getBalance($address) {
  console.log($address)
  let data = {
    address: $address
  }
  let balance = await client.api.network.getBalance(data);
  console.log(balance)
  return balance
}

export async function getNetwork() {
  let network = await client.api.network.getNetwork()
  console.log(network)
  return network
}

export async function getProvider() {
  let provider = await client.api.provider.getProvider();
  console.log(provider)
  return provider
}

export const fetchBalances = async (account) => {
    const coins = state.coins
    const args = [{ type: 'Address', value: account }]
    const balanceFutures = coins.map(coin =>
        client.api.smartContract.invokeRead({ scriptHash: coin.address, operation: 'balanceOf', args })
    );
    let results = await Promise.all(balanceFutures);
    var balances = results.map((value, i) => {
        let balance = utils.bigIntFromBytes(value)
        const num = new BigNumber(balance).div(10 ** coins[i].decimal)
        balance = num.toNumber()
        return { [coins[i].name]: balance }
    })

    return Object.assign({}, ...balances)
}

export async function approveAddLiquidity(susd, usdt, usdc, minMinted) {
    const tokenSusdInfo = state.coins.find(v => v.name === "SUSD")
    const tokenUsdtInfo = state.coins.find(v => v.name === "USDT")
    const tokenUsdcInfo = state.coins.find(v => v.name === "USDC")

    const params = {
        scriptHash: state.contract_addr,
        operation: 'approve_add_liquidity',
        gasPrice: 2500,
        gasLimit: 2000000,
        /* 此处要确认 */
        args: [
            { type: 'Address', value: account },
            {
                type: 'Long',
                value: (new BigNumber(susd)).multipliedBy(10 ** tokenSusdInfo.decimal).toFixed(0)
            }, {
                type: 'Long',
                value: (new BigNumber(usdt)).multipliedBy(10 ** tokenUsdtInfo.decimal).toFixed(0)
            }, {
                type: 'Long',
                value: (new BigNumber(usdc)).multipliedBy(10 ** tokenUsdcInfo.decimal).toFixed(0)
            }, {
                type: 'Long',
                value: (new BigNumber(minMinted)).multipliedBy(10 ** tokenSusdInfo.decimal).toFixed(0)
            }
        ]
    }

    console.log(params)
    let response = await client.api.smartContract.invokeWasm(params)
    return response
}

export async function exchange(tokenIn, tokenOut, inAmount, minOutAmount) {
    const tokenInInfo = state.coins.find(v => v.name === tokenIn)
    const tokenOutInfo = state.coins.find(v => v.name === tokenOut)
    const params = {
        scriptHash: state.contract_addr,
        operation: 'approve_exchange',
        gasPrice: 2500,
        gasLimit: 2000000,
        /* 此处要确认 */
        args: [
            { type: 'Address', value: account },
            { type: 'Integer', value: tokenInInfo.index },
            { type: 'Integer', value: tokenOutInfo.index },
            {
                type: 'Long',
                value: (new BigNumber(inAmount)).multipliedBy(10 ** tokenInInfo.decimal).toFixed(0)
            }, {
                type: 'Long',
                value: (new BigNumber(minOutAmount)).multipliedBy(10 ** tokenOutInfo.decimal).toFixed(0)
            }
        ]
    }

    console.log(params)

    let response = await client.api.smartContract.invokeWasm(params)

    return response
}

export async function getSwappedTokenAmount(tokenIn, amount, tokenOut) {
    const tokenInInfo = state.coins.find(v => v.name === tokenIn)
    const tokenOutInfo = state.coins.find(v => v.name === tokenOut)
    let underlyingAmount = new BigNumber(amount)
    underlyingAmount = underlyingAmount.multipliedBy(10 ** tokenInInfo.decimal)

    const args = [
        { type: 'Long', value: tokenInInfo.index.toString() },
        { type: 'Long', value: tokenOutInfo.index.toString() },
        { type: 'Long', value: underlyingAmount.toFixed() },
    ]

    const response = await client.api.smartContract.invokeWasmRead({
        scriptHash: state.contract_addr,
        operation: 'get_dy_underlying',
        args: args
    })

    const reader = new utils.StringReader(response)
    const swapAmount = reader.readUint128() / (10 ** tokenOutInfo.decimal)

    return swapAmount;
}

class ContractState {
    coins // : [NeoOep4Token; N_COINS],
    balances// : [U128; N_COINS], // 采用底层token精度
    fee//: U128,                 // fee* 1e10
    admin_fee//: U128,           // admin_fee *1e10
    owner//: Address,
    future_owner//: Address,
    initial_amp//: U128,
    future_amp//: U128,
    initial_amp_time//: u64,
    future_amp_time//: u64,
    is_killed//: bool,
}

function readAddress(reader) {
    const addr = utils.reverseHex(reader.read(20))
    return addr
}

export async function getContractState() {
    const response = await client.api.smartContract.invokeWasmRead({
        scriptHash: state.contract_addr,
        operation: 'get_global_state',
    })

    const reader = new utils.StringReader(response)
    return {
        coins: [readAddress(reader), readAddress(reader), readAddress(reader)],
        balances: [reader.readUint128(), reader.readUint128(), reader.readUint128()],
        fee: reader.readUint128(),
        admin_fee: reader.readUint128(),
        owner: readAddress(reader),
        future_owner: readAddress(reader),
        initial_amp: reader.readUint128(),
        future_amp: reader.readUint128(),
        initial_amp_time: reader.readUint64(),
        future_amp_time: reader.readUint64(),
        is_killed: reader.readBoolean(),
    };
}
export function getState(val){
  let obj = {
    [state.coins[0].name]:val[0]/(10 ** state.coins[0].decimal),
    [state.coins[1].name]:val[1]/(10 ** state.coins[1].decimal),
    [state.coins[2].name]:val[2]/(10 ** state.coins[2].decimal),
  }
  return obj
}

export async function getBalanceOfLp() {
    const response = await client.api.smartContract.invokeWasmRead({
        scriptHash: state.contract_addr,
        operation: 'balanceOf',
        /* 此处要确认 */
        args: [
            { type: 'Address', value: account },
        ]
    })

    const reader = new utils.StringReader(response)
    return reader.readUint128()
}

export async function removeLiquidity(lp, susd, usdt, usdc) {
    const tokenSusdInfo = state.coins.find(v => v.name === "SUSD")
    const tokenUsdtInfo = state.coins.find(v => v.name === "USDT")
    const tokenUsdcInfo = state.coins.find(v => v.name === "USDC")

    const params = {
        scriptHash: state.contract_addr,
        operation: 'remove_liquidity',
        gasPrice: 2500,
        gasLimit: 2000000,
        args: [
            { type: 'Address', value: account },
            {
                type: 'Long',
                value: (new BigNumber(lp)).multipliedBy(10 ** tokenSusdInfo.decimal).toFixed(0)
            }, {
                type: 'Long',
                value: (new BigNumber(susd)).multipliedBy(10 ** tokenSusdInfo.decimal).toFixed(0)
            }, {
                type: 'Long',
                value: (new BigNumber(usdt)).multipliedBy(10 ** tokenUsdtInfo.decimal).toFixed(0)
            }, {
                type: 'Long',
                value: (new BigNumber(usdc)).multipliedBy(10 ** tokenUsdcInfo.decimal).toFixed(0)
            }
        ]
    }

    console.log(params)
    let response = await client.api.smartContract.invokeWasm(params)
    return response
}
export async function getLpTotalSupply() {
  const response = await client.api.smartContract.invokeWasmRead({
      scriptHash: state.contract_addr,
      operation: 'totalSupply',
      args: [ ]
  })

  const reader = new utils.StringReader(response)
  return reader.readUint128()
}