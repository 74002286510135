import './assets/reset.css'
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import locale from "element-ui/lib/locale/lang/zh-CN"; // lang i18n
import zh from './common/lang/zh'
import en from './common/lang/en'
import VueI18n from 'vue-i18n'
Vue.use(ElementUI, { locale });

Vue.config.productionTip = false;
const yuyan = navigator.language.split('-')[0]
let yuyans
switch (yuyan) {
  case 'zh':
    yuyans = 'ch'
    break
  case 'en':
    yuyans = 'en'
    break
  default:
    yuyans = 'en'
}
Vue.use(VueI18n)
let localLang
switch (localStorage.getItem('user_lang')) {
  case 'zh':
    localLang = 'ch'
    break
  case 'ch':
    localLang = 'ch'
    break
  case 'en':
    localLang = 'en'
    break
  default:
    localLang = 'en'
}
const i18n = new VueI18n({
  locale: localLang || yuyans,
  messages: {
    ch: zh,
    zh: zh,
    en: en
  }
})

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
