<template>
  <div class="header">
    <div class="logo">
      ALLEY
    </div>
    <div class="menu">
      <ul>
        <li :class="$route.name == 'Home'?'active':''" @click="toHome()">{{$t('header.home')}}</li>
        <li :class="$route.name == 'Swap'?'active':''" @click="toSwap()">{{$t('header.swap')}}</li>
        <li :class="$route.name == 'Liquidity'?'active':''" @click="toLiquidity()">{{$t('header.liquidity')}}</li>
        <!-- <li @click="Coming()">{{$t('header.ido')}}</li> -->
      </ul>
    </div>
    <div class="connect">
      <div v-if="!able" class="dis-connect" id="connectwallet" @click="connect()">Connect to a wallet</div>
      <div v-else class="able">
        <div class="net">{{net.type}}</div>
        <div class="address">{{address.substr(0,8)}}...{{address.substr(26,8)}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAddress, getNetwork, getProvider, getBalance } from '../../contract/contracts'
export default {
  name: "top",
  props: {
    msg: String,
  },
  data(){
    return {
      address:'',
      net:{},
      able: false,
      providerFlag: false
    }
  },
  methods:{
    async getProvider(){
      let pro = await getProvider()
      console.log(pro)
    },
    async getAddress(){
      this.address = await getAddress()
      sessionStorage.setItem("alley_address", this.address)
      this.net = await getNetwork()
      sessionStorage.setItem("alley_net", this.net.type)
    },
    async connect(){
      try{
      let pro = await getProvider()
      this.able = true
      this.getAddress()
      }catch(e){
        console.log(e)
        window.open('https://chrome.google.com/webstore/detail/dkdedlpgdmmkkfjabffeganieamfklkm')
      }
    },
    Coming(){
      this.$message({ message: 'Coming soon!' })
    },
    toSwap(){
      this.$router.push({name:'Swap'})
    },
    toLiquidity(){
      this.$router.push({name:'Liquidity'})
    },
    toHome(){
      this.$router.push({name:'Home'})
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.header {
  width: 100%;
  height: 80px;
  box-shadow: 0 1px 8px #b5b5b5;
  background-image: radial-gradient(circle, rgb(251 207 227), rgb(247 227 237));
  display: flex;
  .logo {
    width: 200px;
    font-size: 28px;
    font-family: sans-serif;
    font-weight: 600;
    line-height: 80px;
    color: rgba(0, 0, 0, 1);
  }
  .menu {
    flex: 1;
    ul {
      display: flex;
      li {
        cursor: pointer;
        font-size: 28px;
        font-family: sans-serif;
        font-weight: 600;
        line-height: 80px;
        color: rgba(0, 0, 0, 0.6);
        margin-right: 50px;
        position: relative;
      }
      .active:after{
        content: '';
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;
        height: 4px;
        background-color: #ff027a;
      }
    }
  }
  .connect{
    width: 300px;
    display: flex;
    flex-flow: row;
    align-items: center;
    .dis-connect{
      cursor: pointer;
      width: 180px;
      height: 30px;
      border-radius: 15px;
      background-color: #f2cccc;
      font-size: 16px;
      color: #f04a4a;
      line-height: 30px;
    }
    .able{
      display: flex;
      .net{
        width: 60px;
        height: 30px;
        border-radius: 15px;
        font-size: 16px;
        background-color: #fff;
        color: #ff027a;
        font-weight:bold;
        line-height: 30px;
      }
      .address{
        margin-left: 20px;
        width: 200px;
        height: 30px;
        border-radius: 15px;
        font-size: 16px;
        background-color: #fff;
        color: #ff027a;
        font-weight:bold;
        line-height: 30px;
      }
    }
  }
}
</style>
