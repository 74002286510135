<template>
  <div id="app">
    <head-div></head-div>
    <router-view />
  </div>
</template>
<script>
import headDiv from './components/common/head'
export default {
  name: 'App',
  components: {
    headDiv
  },
}
</script>
<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: rgba(243, 233, 233,0);
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
