<template>
  <div class="banner">
    <div class="content">
      <span>{{$t('home.slogan1')}}</span>
      <span>{{$t('home.slogan2')}}</span>
      <div class="swap" @click="toSwap()">Swap Now!</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  methods:{
    toSwap(){
      this.$router.push({name:'Swap'})
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.banner::after {
  content: "";
  background-image: url("../../assets/back.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  opacity: 0.2;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}
.banner {
  height: calc(100vh - 80px);
  background-color: rgba(255,255,255,0);
  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    display: flex;
    flex-flow: column;
    align-items: center;
    span {
      font-size: 60px;
      line-height: 70px;
      color: #000;
    }
    .swap{
      cursor: pointer;
      margin-top: 20px;
      width: 200px;
      height: 40px;
      border-radius: 30px;
      background-color: #fff;
      font-size: 20px;
      line-height: 40px;
      text-align: center;
      transition: all ease 0.3s;
    }
    .swap:hover{
      background-color: #000;
      color: #fff;
      transition: all ease 0.3s;
    }
  }
}
</style>
